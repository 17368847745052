<template>
  <v-container style="height: 100vh" class="bg-glass-effect pa-10">
    <v-layout style="height: 85vh" align-center justify-center>
      <v-row align="center" justify="center">
        <v-col class="pl-9" cols="4">
          <v-img
              style="max-height: 70px; max-width: 70px"
              src="/img/app-logo-70.svg"
              alt="Eu Protegido"
          />
        </v-col>
        <v-col class="pl-0" cols="8">
          <p class="main-title ma-0">Eu Protegido</p>
        </v-col>

        <v-col cols="12">
          <p v-if="os.toUpperCase().includes('ANDROID')" class="main-text text-center">
            Para instalar, utilize o navegador Chrome
          </p>
          <p v-else class="main-text text-center">
            Para instalar, utilize o navegador Safari
          </p>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "notInstall",
  data: () => ({
    os: null,
  }),
  beforeMount() {
    const browser = this.$route.params.browser
    this.os = this.$route.params.os
    if (browser == 'chrome'){
      this.isSafari = true
    }if (browser == 'safari'){
      this.isChrome = true
    }
  },
  methods:{
    Browser(){

    }
  }
}
</script>
